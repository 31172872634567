.aboutUs_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.aboutUs_container_first {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.image-card {
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  margin: 12px;
}

#lightbox-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-doctor__image {
  padding: 30px;
  img {
    width: 90%;
  }
}

.about-doctor__details {
  padding: 40px;

  h2 {
    color: #2A367A;
    font-size: 2.3rem;
    margin-bottom: 30px;
  }

  p {
    line-height: 2.1;
    font-size: 20px;
    margin-bottom: 0;
  }
}

.about-doctor__name {
    color: #2A367A;
    //margin-top: 20px;
}

.gallery__name {
  padding: 0 10px;
  color: #2A367A;
}




/*Completely optional styling that has nothing to do with the lightbox*/
button {
  color: white;
  border: 2px solid #2A367A;
  background-color: #2A367A;
  font-size: 1.2rem;
}

a:hover, a.active {
  color: #2A367A;
}

h1 {
  color: #2A367A;
}

p {
  margin-bottom: 1.5rem;
  font-size: .9rem;
}

img:hover, button:hover {
  cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 1400px){
  .about-doctor__image {
    padding: 0;
  }
  .about-doctor__name {
    margin-top: 0;
  }
  .about-doctor__details p {
    line-height: 1.8;
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .about-doctor__image {
    padding: 0;
  }
  .about-doctor__details {
    padding: 0;

    h2 {
      font-size: 2rem;
      margin-bottom: 8px;
    }

    p {
      line-height: 1.6;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .aboutUs_container {
    margin-bottom: 0;
  }
  
  .about-doctor__details h2 {
    margin-top: 20px;
  }

  #lightbox-img {
    height: 50vh;
    max-width: 50vw;
  }

  .gallerySection, .gallery__name {
    padding: 0;
  }

  .image-card {
    width: 20vw;
    height: 20vw;
    margin: 5px;
  }
}

