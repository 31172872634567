:root {
    
    /* colors */
    --background-color: #fafafa;
    --card-bg: #fff;
    --cyan: hsl(180, 62%, 55%);
    --red: hsl(0, 78%, 62%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --dark-blue: hsl(234, 12%, 34%);
    --gray: hsl(229, 4%, 43%);

    /* fonts */
    --body-text-size: 1.5rem;
}


.header {
    font-size: 2.6rem; 
    font-weight: 200;
    color: var(--dark-blue);
    line-height: 1.5;
    text-align: center;
}

.header--bold {
    font-weight: 600;
}


.intro {
    color: var(--gray);
    font-size: var(--body-text-size);
    line-height: 1.3;
    margin: 25px auto;
    max-width: 310px;
    text-align: center;
}

.cards {
    margin-top: 80px;
    margin-bottom: 50px;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 350px);
    align-items: center;
    display: grid;        
    justify-content: center;
}

.card {
    background-color: var(--card-bg);
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 14px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 10px 14px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 10px 14px 1px rgba(0,0,0,0.15);
    margin: 0 auto 25px auto;
    max-width: 310px;
    padding: 25px 30px;

    .knowMore {
        padding: 0 10px;
        font-size: 12px;
        color: #2A367A;
        font-weight: bold;
    }
}

.card__heading { 
    color: #2A367A;
    font-size: 17px;
    font-weight: 500;
    padding: 0 10px;
}


.card__text {
    color: var(--gray);
    line-height: 1.6;
    padding: 0 10px;
    margin-bottom: 5px;
}

.card__icon { 
    margin-bottom: 35px;
    text-align: right;
}

.card__icon img {
    width:100%
}

.card--supervisor {
    border-top: 5px solid var(--cyan);
}

.card--team-builder {
    border-top: 5px solid var(--red);
}

.card--karma {
    border-top: 5px solid var(--orange);
}

.card--calculator {
    border-top: 5px solid var(--blue);
}

.modalDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 99999;
    opacity:0;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: none;
}
.modalDialog:target {
    opacity:1;
    pointer-events: auto;
}
.modalDialog > div {
    max-width: 800px;
    width: 80%;
    position: relative;
    margin: 15% auto;
    padding: 20px;
    border-radius: 3px;
    background: #fff;

    p {
        padding: 0 10px;
    }
}
.close, .close:hover {
    font-family: Arial, Helvetica, sans-serif;
    background: #2A367A;
    color: #fff;
    line-height: 25px;
    position: absolute;
    right: -15px;
    text-align: center;
    top: -20px;
    width: 34px;
    height: 34px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
    padding-top: 5px;
    opacity: 1;
    font-size: 17px;
    font-weight: 200;
}
.close:hover {
    background: #2A367A;
}

.attribution { font-size: 11px; text-align: center; }
.attribution a { color: hsl(228, 45%, 44%); }

@media screen and (min-width: 992px) and (max-width:1160px) {
    .cards {
        grid-gap: 20px;
        grid-template-columns: repeat(3, 300px);
    }
    .card {
        max-width: 300px;
        padding: 25px 30px;        
    }
}

@media screen and (min-width: 768px) and (max-width:992px) {
    .cards {
        grid-gap: 10px;
        grid-template-columns: repeat(3, 230px);
        margin-top: 40px;
    }
    .card {
        max-width: 230px;
        padding: 10px;        
    }

    .card__icon {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {    

    .card {
        //max-width: 350px;
        padding: 25px 30px;
    }

    .header {
        font-size: 3.5rem;
    }
    
    .intro { 
        margin-bottom: 65px;
        margin-top: 25px;
        max-width: 500px;
    }
}

@media screen and (max-width: 767px) {
    .cards {
        margin-top: 50px;
        grid-template-columns: auto;
        grid-gap: 0;
        margin-bottom: 0;
    }

    .card {
        max-width: 250px;
        padding: 15px 20px;
    }

    .card__icon {
        margin-bottom: 15px;
    }

    .card__heading {
        font-size: 14px;
    }  
    
    .card__text {
        font-size: 12px;
    }
    
}