.homeDetails__left_bgImage {
    background-image: url(https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/young-woman-checking-on-eye-in-clinic.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.09;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.homeDetails__left {
    background-color: #5d679f;
    color: #ffffff;
    // padding: 210px;
    position: relative;

    h1 {
        color: #ffffff;
    }

    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .box_inner {
            display: flex;
            flex-direction: column;
            width: 70%;
        }
    }
}

.homeDetails__right {
    padding: 0;
}

@media screen and (min-width:768px) and (max-width:992px) {
    .homeDetails__left  {
        h1 {
            font-size: 24px;
            padding-top: 10px;
        }

        p {
            font-size: 14px;
        }
    }
}

@media screen and (max-width:767px) {
    .homeDetails__left  {
        h1 {
            font-size: 22px;
            padding-top: 20px;
        }

        p {
            font-size: 14px;
        }
    }
}