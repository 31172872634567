
.book-appointment_wrapper {
    justify-content: flex-end;
    display: flex;
  }

  @media screen and (max-width:991px) {
    .nav {
        .container {
            max-width:100%
        }        

        .nav-item {
            font-size: 16px;
        }
    }
  }
  
  @media screen and (max-width:767px) {
    .nav-items {
      padding-left: 15px;
  
      .nav-item {
        font-size: 14px;
        margin-left: 1rem;
      }
    }
  
    .book-appointment__btn {
      padding: 5px 10px;
      font-size: 12px;
    }
  }

  @media screen and (max-width:458px) {
    .nav-items {
      padding-left: 0;
    }
    .nav-content .col-md-6 {
      width: 100%;
      display: block;
    }
    .nav-items .nav-item {
      font-size: 12px;
      margin-left: 10px;
    }

    .book-appointment__btn {
      padding: 2px;
      font-size: 10px;
      text-transform: capitalize;
    }
  }