body, #root {
    overflow-x: hidden;
}

.nav {
    background-color: #2A367A;
    color: #ffffff;
    position: sticky;
    top: 0px;
    width: 100%;
    height: 80px;
    z-index: 1000;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  }
  
  .nav-items {
    margin: 0;
    padding-left: 15px;
  }
  
  .nav-logo {
    width: 220px;
  }
  
  .nav-logo:hover {
    cursor: pointer;
  }
  
  .nav .nav-content {   
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%
  }
  
  .nav-item {
    display: inline;
    margin-left: 2rem;
    color: #ffffff;
    font-size: 18px;
  }

  .nav-item a.active {
    color: #ffffff;
    font-weight: bold;
    border-bottom: 2px solid #ffffff;
  }

  .nav-items .nav-item:first-child {
    margin-left: 0;
  }
  
  .nav-item:hover {
    color: #bca0ff;
    cursor: pointer;
  }
  
  .section-content {
    margin: 0 auto;
    padding: 40px;
  }

  .book-appointment__btn {
    background-color: #ffffff;
    color: #2A367A;
    font-weight: 700;
  }

  .book-appointment__btn a {
    color: #2A367A;
  }

  .book-appointment__btn:hover {
    color: #313f8d;
  }

  .footerContainer a {
    color: #ffffff;
  }

  .footerContainer .footerBtn {
    background-color: #ffffff;
    color: #313f8d;
  }

  .footerContainer .tejomayLink {
    padding-left: 5px;
  }

  .footerContainer .tejomayLink:hover {
    color: #ffffff;
    padding-left: 5px;
    border-bottom: 1px solid #ffffff;    
  }

  .footer_addressEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }

  .footer_addressEmailContainer {
    display: inline-flex;    
    align-items: center;
  }

  .footer_addressEmailIcon {
    width: 45px;
  }

  .footer_addressEmailInfo {
    margin-left: 0;
    align-items: center;
  }

  .footer_header {
    justify-content: flex-start;
  }

  .footer_detailsHeader {
    font-size: 20px;
    margin-left: 35px;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #05C18E;
    width: auto;
    justify-content: flex-start;
  }

  .copyRight_text {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .footerHeader_Container {
      width: 40%;
    }
    .footerEmpty_container {
      width: 10%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .footerHeader_Container {
      width: 50%;
    }
    .footerEmpty_container {
      display: none;
    }

    .footer_addressEmailInfo {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 458px) {
    .footer_addressEmailInfo {
      font-size: 12px;
    }

    .copyRight_text {
      font-size: 10px;
    }
  }