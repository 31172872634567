.contactUs_section {
    // box-shadow: 0px 3px 35px 0px rgb(42 54 122 / 10%);
    background-color: #ffffff;
    margin-top:60px;
    margin-bottom: 60px;
    
    .contactUs_form {
        //box-shadow: 0px 3px 35px 0px rgb(42 54 122);
        box-shadow: 0px 3px 35px #ccc;
        padding: 40px !important;
    }

    input, textarea {
        border-radius: 5px;
        border: 1px solid #ededed;
    }
    input:hover, textarea:hover {
        border-color: #2A367A;
    }    
}

.contactUs_btn {
    Button {
        background-color: #ffffff; 
        color: #2A367A;       
        font-weight: 700;
        border-radius: 0.25rem;
        border-color:  #2A367A;
    }  
    
    .contactUs_submit {
        background-color: #2A367A; 
        color: #ffffff;     
    }
}

.contactUs_map {
    h2 {
        color: #2A367A;
        margin-bottom: 20px;
    }
}

.drName_contactNumber {
    margin-top: 20px;
    color: #2A367A;

    i {
        margin-right: 10px;
        color: #05C18E;
    }
    span {
        color: #4f4f4f;

        &:hover {
            color: #05C18E; 
        }
    }
}

@media screen and (min-width:768px) and (max-width:992px) {
    .contactUs_section {
        .contactUs_form {
            padding: 20px !important;
        }

        input, textarea {
            padding: 5px !important;
        }

        button {
            padding: 2px !important;
        }
    }

    .contactUs_map {
        iframe {
            height: 250px;
        }
    }
}

@media screen and (max-width:767px) {
    .contactUs_section {
        margin-top: 30px;
        margin-bottom: 0;

        .contactUs_form {
            padding: 20px !important;
        }

        input, textarea {
            padding: 10px !important;
        }

        button {
            padding: 2px !important;
        }
    }

    .contactUs_map {
        padding: 0 !important;
        h2  {
            margin-top: 20px;
        }
    } 
}