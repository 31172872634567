.section-content h1 {
    text-align: center;
    span {
        padding: 0 15px;
        border-top: 2px solid #2A367A;
        border-bottom: 2px solid #2A367A;
        color: #05C18E;
    }
}

.section-content.bgColor {
    background-color: #5d679f;
    h1 {
        span {
            color: #ffffff;
        }
    }
}