.headerDetails {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    height: 100%;
}

.headerDetailsRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headerDetails__info {
    font-size: 14px;
    align-items: center;
    margin-left: 0;
}

.headerIcon {
    justify-content: flex-end;
    display: inline-flex;    
    padding: 0 5px;
}

.headerIcon i {
    color: #2A367A;
    width: 45px;
    text-align: center;
}

@media screen and (max-width:1200px) {
    .headerDetails__info {
        font-size: 12px;
    }
}

@media screen and (max-width:991px) {
    .logoContainer {
        width: 100%;
        text-align: center;
    }

    .headerDetails_container {
        display: none;
    }
}